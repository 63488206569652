import React, { useState, useMemo } from "react";
import "./Cards.css";
import { cardData } from "../data/CardData";
import Pagination from "./Pagination";
import { Container, Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap";

const Crads = () => {
    const PageSize = 16;

    const [currentPage, setCurrentPage] = useState(1);

    const rawData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return cardData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);

    return (
        <div className="bg-image">
            <div className="games-title">
                <h2 className="the-milky-way" >Explore Our Top Games: Fish Games, Slots & Sweepstakes</h2>
            </div>
            <Container>
                <Row>
                    {rawData?.map((item, index) => (
                        <Col xs={12} sm={12} md={6} lg={3} xl={3} key={index + 1}>
                            <Card className="my-2 card-override">
                                <img
                                    className="card-image"
                                    alt={index + 1}
                                    src={`../assets/game/${item?.image}`}
                                />
                                <CardBody className="card-body-override">
                                    <CardTitle className="guns-coins">{item?.name}</CardTitle>
                                </CardBody>
                                <div className="card_btn">
                                        <Button onClick={() => window.open('https://www.facebook.com/people/MilkyWay-Online/61564151433682/', '_blank')}>Play Now</Button>
                                    </div>
                            </Card>
                        </Col>
                    ))}
                </Row>

                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={cardData?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </Container>
            {/* <div className="carousel-controls">
                <div className="control-buttons">
                    <button className="button1">
                        <b className="previous-page">Previous Page</b>
                    </button>
                    <button className="button2">
                        <b className="next-page">Next Page</b>
                    </button>
                </div>
            </div> */}
        </div>
    );
};

export default Crads;
