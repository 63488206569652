import { FaFacebookMessenger } from "react-icons/fa6";
import './ChatMessenger.css'

const ChatMessenger = () => {

    const redirectToMessenger = () => {
        window.open('https://m.me/400936276434141', '_blank');
    };
    return (
        <div>
            <div className="messenger-icon" onClick={redirectToMessenger}>
                <FaFacebookMessenger size={'30px'} />
            </div>
        </div>
    )
}

export default ChatMessenger
