import React from 'react';
import { useNavigate } from 'react-router-dom';
import blogData from '../../data/blogData'; // Adjust the import path as necessary
import { Helmet } from 'react-helmet';
import RecentBlogs from '../RecentBlogs';

const BlogCategoryPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
          <title>Casino Blog | Tips, Strategies & Latest Updates | Milky Way 777 Slots</title>
          <meta name="description"
            content="Stay ahead in the world of online gaming with MilkyWay777 Slots blog. Explore expert casino tips, the latest slot game updates, strategies, and more." />
        <body className='blog-body'></body>
      </Helmet>
      <section className="all-blog">
        <div className="container">
          <div className="blog-head ">
            <h1> Blogs</h1>
          </div>
          <div className="row recent-blog gy-4">
            <div className="col-lg-8">
              <div className="row gy-4">
                {blogData.map(blog => (
                  <div className="col-md-6">
                    <div className="common-blog">
                      <img src={blog.image} alt="" />
                      <div className="common-blog-content">
                        {/* <p>slot game win</p> */}
                        <h4>{blog.metaTitle}</h4>
                        <button onClick={() => navigate(`/blog/${blog.slug}`)}>Read More</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="common-recent-detail">
                <RecentBlogs blogData={blogData} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogCategoryPage;
