import React from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "reactstrap/lib";
import { RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import BlogCategoryPage from "./components/Blogs/BlogCategoryPage";
import BlogLayout from "./components/Blogs/BlogLayout";

const router = createBrowserRouter([
  {
    path : '/',
    element : <Layout />,
    children : [
      {
        index : true,
        element : <Home />
      },
      {
        path : '/blogs',
        element : <BlogCategoryPage />
      },
      {
        path: 'blog/:blogId',
        element: <BlogLayout />
      },
      {
        path : '*',
        element : <NotFound />
      }
    ]
  }
])

function App() {
  return (
    <div className="component-wrapper">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
