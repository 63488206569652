import React from 'react'
import { Link } from 'react-router-dom'

const RecentBlogs = ({blogData}) => {
    return (
                <div className="recent-post">
                    <h3>Recent Posts</h3>
                    {blogData.map(blog => (
                        <div className="common-post" key={blog.id}>
                            <Link to={`/blog/${blog.slug}`}> <img src={blog.image} alt="" />
                                <p>{blog.title}</p>
                            </Link>
                        </div>
                    ))}
                </div>
    )
}

export default RecentBlogs
