import React from 'react'
import NavBar from './NavBar'
import AnnouncementBar from './Annoucement'
import { Outlet } from 'react-router-dom'
import ScrollButton from './ScrollButton'
import Footer from './Footer'
// import ChatMessenger from './ChatMessenger'
// import ChatIndex from './chatbot/ChatIndex'

const messages = [
  "Sign up today and receive an incredible 200% Bonus on your first deposit!",
];

const Layout = () => {
  return (
    <React.Fragment>
        <AnnouncementBar messages={messages}/>
        <NavBar />
        <Outlet />
        <Footer />
        <ScrollButton />
    </React.Fragment>
  )
}

export default Layout
