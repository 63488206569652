import React from 'react'
import { FaFacebookF, FaWhatsapp } from "react-icons/fa";

const ShareBox = ({url}) => {

    function shareOnWhatsApp() {
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(url)}`;

        // Window dimensions
        const width = 500;
        const height = 600;

        // Calculate the center position
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        // Open the new window at the center
        window.open(whatsappUrl, 'whatsappShare', `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`);
    }
    function shareOnFacebook() {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;

        // Window dimensions
        const width = 500;
        const height = 600;

        // Calculate the center position
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        // Open the Facebook share link in a new centered window
        window.open(facebookUrl, 'facebookShare', `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`);
    }
    return (
        <div className="recent-post share-icon">
            <h3>Share</h3>
            <ul>
                <li><FaFacebookF onClick={shareOnFacebook} /></li>
                {/* <li><a href="/"><FaInstagram /></a></li> */}
                <li><FaWhatsapp onClick={shareOnWhatsApp} /></li>
            </ul>
        </div>
    )
}

export default ShareBox
