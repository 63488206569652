import React from 'react'

const Footer = () => {
  return (
    <div className="copy-wrapper">
      <div className="copy-right">
                © Copyright 2024 Milky Way All Rights Reserved
            </div>
    </div>
  )
}

export default Footer
