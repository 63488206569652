// src/data/blogData.js

import Blog_1 from "../components/Blogs/Blog_1";

let blogItems = [
    {
      id: '1',
      slug : 'free-slot-games-for-android-phones' ,
      image : '/assets/blogs/blog-one.webp',

      metaTitle: 'How to Choose the Best Free Slot Game for Android Phone Users?',
      title: 'How to Choose the Best Free Slot Game for Android Phone Users?',
      content: 'Content of blog 1',
      metaDescription: 'Here are some things to look for before signing up for the slot games for Android! From top-rated apps, enjoying bonuses, to experiencing fun gaming.',
      canonical : 'https://milkyway777slots.com/blog/free-slot-games-for-android-phones',

      ogTitle : 'How to Choose the Best Free Slot Game for Android Phone Users?',
      ogDescription : 'Here are some things to look for before signing up for the slot games for Android! From top-rated apps, enjoying bonuses, to experiencing fun gaming.',
      ogImage : 'https://milkyway777slots.com/assets/blogs/blog-one.webp',
      component : Blog_1 ,
      sharingUrl : 'https://milkyway777slots.com/blog/free-slot-games-for-android-phones',
      date: '2024-11-25'
    },
    // {
    //   id: '2',
    //   title: 'Blog 2',
    //   content: 'Content of blog 2',
    //   metaDescription: 'Description for Blog 2'
    // },
    // Add more blogs as needed
  ];
  
  let blogData = blogItems.sort((a, b) => new Date(b.date) - new Date(a.date));
  export default blogData;
  