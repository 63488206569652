import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import blogData from '../../data/blogData'; // Adjust the import path as necessary

const BlogLayout = () => {
  const { blogId } = useParams();
  const blog = blogData.find(blog => blog.slug === blogId);

  const BlogComponent = blog.component;
  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.metaDescription} />
        <link rel="canonical" href={blog.canonical} />
        {/* OG Tags */}
        <meta property="og:title" content={blog.metaTitle} />
        <meta property="og:site_name" content="Milky Way 777 Slots/" />
        <meta property="og:url" content={blog.sharingUrl} />
        <meta property="og:description" content={blog.ogDescription}/>
        <meta property="og:type" content="website" />
        <meta property="og:image" content={blog.ogImage} />
        <meta property="og:type" content='article' />
      </Helmet>


      {BlogComponent ? <BlogComponent image={blog.image} url={blog.sharingUrl} /> : <p>{blog.content}</p>}
    </>
  );
};

export default BlogLayout;
